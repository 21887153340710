import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/scheduler");
            result.scheduler.forEach((e) => {
                e.start = new Date(e.start);
                e.end = new Date(e.end);

                e.event_id = e.id;
            });
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    },
    create: async (scheduler = {}) => {
        try {
            scheduler.deletable = true;
            scheduler.editable = true;
            scheduler.draggable = true;
            let result = await api.post("/api/v1/private/scheduler", scheduler);
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    },
    delete: async (id) => {
        try {
            let result = await api.del("/api/v1/private/scheduler", { id });
        } catch (err) {
            throw { message: err.message };
        }
    },
    update: async (scheduler = {}) => {
        try {

            scheduler.id = scheduler.event_id;
            scheduler.deletable = true;
            scheduler.editable = true;
            scheduler.draggable = true;
            
            let result = await api.post("/api/v1/private/scheduler", scheduler);
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    },
}