import api from '../../api';

export default {
    create: async (produit = {}) => {
        try {
            let result = await api.post("/api/v1/private/produit", produit);
            return { product: result.produit }
        } catch (err) {
            throw { message: err.message };
        }
    },
    update: async (produit = {}) => {
        try {
            let result = await api.post("/api/v1/private/produit", produit);
            return { product: result.produit }
        } catch (err) {
            throw { message: err.message };
        }
    },
    delete: async (id) => {
        try {
            let result = await api.del("/api/v1/private/produit", { id });
        } catch (err) {
            throw { message: err.message };
        }
    },
}