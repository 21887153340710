import api from '../../api';

export default {
    getFromPlate: async (plate) => {
        try {
            let result = await api.get("/api/v1/private/vehicule", { query: { plate: plate } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    },
    save: async (vehicule) => {
        try { 
            let result = await api.post("/api/v1/private/vehicule", vehicule);
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    },
    setSelected: async (vehicule = {}) => {
        try {
            return {
                selectedVehicule: { ...vehicule }
            };
        } catch (err) {
            throw { message: err.message };
        }
    },
    delete: async (id) => {
        try {
            let result = await api.del("/api/v1/private/vehicule", { id });
        } catch (err) {
            throw { message: err.message };
        }
    },
    getByTecdocId: async (tecdocid) => {
        try {
            let result = await api.get("/api/vehicule", { query: { tecdocid: tecdocid } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    }
}