import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withSnackBar } from '@remyar/react-snackbar';

import Box from '@mui/material/Box';
import { Scheduler } from "@aldabil/react-scheduler";

import actions from '../../actions';
import locale from '../../locales';

function SchedulPage(props) {
    const intl = props.intl;

    const [displayLoader, setDisplayLoader] = useState(false);
    const [events, setEvents] = useState([]);

    async function fetchData(loader = false) {
        setDisplayLoader(loader);
        try {
            let result = await actions.scheduler.getAll();
            setEvents(result.scheduler.filter(el => ((el.deleted !== 1) && (el.deleted !== true))));
        } catch (err) {
            props.snackbar.error(err.message);
        }
        setDisplayLoader(false);
    }

    useEffect(() => {
        fetchData(true);
    }, []);

    return <Box sx={{ paddingBottom : "25px"}}>
        <Scheduler
            hourFormat={24}
            deletable={true}
            editable={true}
            draggable={true}
            events={events}
            locale={locale.getLocale("fr")}
            week={{
                startHour: 7,
                endHour: 22  
            }}
            day={{
                startHour : 7,
                endHour : 22
            }}
            fields={[{
                name: "color",
                type: "select",
                default: "blue",
                options: [
                    { id: 1, text: "Noir", value: "black" },
                    { id: 2, text: "Bleu marine", value: "navy" },
                    { id: 3, text: "Bleu", value: "blue" },
                    { id: 4, text: "Vert", value: "green" },
                    { id: 5, text: "Bleu sarcelle", value: "teal" },
                    { id: 6, text: "Vert citron", value: "lime" },
                    { id: 7, text: "Cyan", value: "aqua" },
                    { id: 8, text: "Bordeaux", value: "maroon" },
                    { id: 9, text: "Pourpre", value: "purple" },
                    { id: 10, text: "Olive", value: "olive" },
                    { id: 11, text: "Gris", value: "gray" },
                    { id: 12, text: "Argent", value: "silver" },
                    { id: 13, text: "Rouge", value: "red" },
                    { id: 14, text: "Jaune", value: "yellow" },
                ],
                config: { label: "Couleur" }
            }]}
            translations={
                {
                    navigation: {
                        month: "Mois",
                        week: "Semaine",
                        day: "Jour",
                        today: "Aujourd'hui",
                        agenda: "Agenda"
                    },
                    form: {
                        addTitle: "Ajouter un rendez-vous :",
                        editTitle: "Modifier un rendez-vous :",
                        delete: "Supprimer",
                        cancel: "Annuler",
                        confirm: "Valider",
                    },
                    event: {
                        title: "Titre",
                        subtitle: "Commentaire",
                        start: "Date et heure du rendez-vous",
                        end: "Fin du rendez-vous",
                        allDay: "Tous les jours"
                    },
                    validation: {
                        required: "Requis",
                        invalidEmail: "Email invalide",
                        onlyNumbers: "Uniquement des chiffres",
                        min: "Minimum {{min}} lettres",
                        max: "Maximum {{max}} lettres"
                    },
                    moreEvents: "plus...",
                    noDataToDisplay: "Pas de données a affichée",
                    loading: "Chargement..."
                }
            }
            onDelete={async (id) => {
                try {
                    await actions.scheduler.delete(id);
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
            onConfirm={async (event, action) => {
                try {
                    switch (action) {
                        case "create":
                            await actions.scheduler.create(event);
                            break;
                        case "edit":
                            await actions.scheduler.update(event);
                            break;
                    }
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
            onEventDrop={async (event, droppedOn, updatedEvent, originalEvent) => {
                try {
                    await actions.scheduler.update(updatedEvent);
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
        />
    </Box>
}

export default withSnackBar(injectIntl(SchedulPage));