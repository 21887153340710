import createAction from '../../middleware/actions';
import api from '../../api';

export default {
    getAll: async () => {
        try {
            let cp = await api.get("/api/v1/public/codepostaux");
            return { __code_postaux__: cp.code_postaux };
        } catch (err) {
            throw { message: err.message };
        }
    },
    getVillesFromCp: async (codePostale) => {
        try {
            let cp = await api.get("/api/v1/public/codepostaux");
            if (codePostale.trim().length >= 2) {
                let value = cp.map((el) => {
                    if (el.code_postal.toString().startsWith(codePostale.trim())) {
                        return el;
                    }
                }).filter((el) => el != undefined);

                return { code_postaux: value };
            }
            return { code_postaux: [] }
        } catch (err) {
            throw { message: err.message };
        }
    }
}