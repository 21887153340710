import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withSnackBar } from '@remyar/react-snackbar';
import { useNavigation } from '@remyar/react-navigation';


import Box from '@mui/material/Box';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDial from '@mui/material/SpeedDial';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import SearchComponent from '../../components/Search';
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable';
import ConfirmModal from '../../components/ConfirmModal';


import actions from '../../actions';
import routeMdw from '../../routes';

function Forfaitspage(props) {
    const intl = props.intl;
    const navigation = useNavigation();

    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(undefined);
    const [filter, setFilter] = useState("");
    const [forfaits, setForfaits] = useState([]);

    async function fetchData() {
        setDisplayLoader(true);
        try {
            let result = await actions.forfaits.getAll();
            setForfaits(result.forfaits.filter((el) => el.deleted == undefined || el.deleted == false));
        } catch (err) {
            props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        { id: 'ref_fab', label: 'Référence', minWidth: 100 },
        { id: 'nom', label: 'Nom', minWidth: 100 },
        {
            label: '', maxWidth: 100, minWidth: 100, align: "right", render: (row) => {
                return <span>
                    <EditIcon sx={{ cursor: 'pointer' }} onClick={(event) => {
                        event.preventDefault();
                        navigation.push(routeMdw.urlForfaitEdit(row.id));
                    }} />
                    <DeleteForeverIcon sx={{ color: 'red', cursor: 'pointer', marginLeft: '15px' }} onClick={(event) => {
                        event.preventDefault();
                        setDisplayConfirmModal(row);
                    }} />
                </span>
            }
        }
    ];

    let rows = forfaits.map((el) => {
        return {
            ...el,
            onClick: (row, rowid, event) => {
                if (event.defaultPrevented == false) {
                    navigation.push(routeMdw.urlForfaitDisplay(el?.id));
                }
            },
            sx: {
                cursor: 'pointer'
            }
        }
    });

    return <Box sx={{ paddingBottom: '25px' }}>

        <Loader display={displayLoader} />

        <SearchComponent onChange={(value) => {
            setFilter(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        }} />

        <br /><br />

        <DataTable sx={{ height: (window.innerHeight - 200) + "px" }} headers={headers} rows={rows} />

        {displayConfirmModal && <ConfirmModal
            title={intl.formatMessage({ id: 'forfaits.delete' })}
            display={displayConfirmModal ? true : false}
            onClose={() => {
                setDisplayConfirmModal(undefined);
            }}
            onValidate={async () => {
                let idToDelete = displayConfirmModal.id;
                setDisplayLoader(true);
                setDisplayConfirmModal(undefined);
                try {
                    await actions.forfait.delete(idToDelete);
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
                }
                setDisplayLoader(false);
            }}
        />}

        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                key={'NewForfait'}
                icon={<AddIcon />}
                tooltipTitle={intl.formatMessage({ id: 'forfaits.add' })}
                onClick={async () => {
                    navigation.push(routeMdw.urlForfaitCreate());
                }}
            />

        </SpeedDial>
    </Box>
}


export default withSnackBar(injectIntl(Forfaitspage));