import api from '../../api';

export default {
    login: async (username, password) => {
        try {
            let result = await api.post("/api/v1/public/user/login", { username, password });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    },
    signup: async (user) => {
        try {
            let result = await api.post("/api/v1/public/user/create", { ...user });
            return { user: result.user };
        } catch (err) {
            throw { message: err.message };
        }
    },
    logout: async () => {
        try {
            let result = await api.post("/api/v1/private/user/logout");
            return { user: undefined };
        } catch (err) {
            throw { message: err.message };
        }
    },
    forgotPassword: async (data) => {
        try {
            let result = await api.post("/api/v1/public/user/forgotpassword", { ...data });
            return { user: undefined };
        } catch (err) {
            throw { message: err.message };
        }
    },
    getSession: async () => {
        try {
            let result = await api.get("/api/v1/public/user/session");
            return { user: result.user, message: result.message };
        } catch (err) {
            throw { message: err.message };
        }
    },
    dumpUserDatabase: async () => {
        try {
            let result = await api.get("/api/v1/private/user/database");
            return { database: result.database };
        } catch (err) {
            throw { message: err.message };
        }
    },
    restoreUserDatabase: async (database) => {
        try {
            let result = await api.post("/api/v1/private/user/database", { database: database });
            return { database: result.database };
        } catch (err) {
            throw { message: err.message };
        }
    },
}