import api from '../../api';
import pdf from '../../pdf';

export default {
    create: async (facture = {}) => {
        try {
            let result = await api.post("/api/v1/private/facture", facture);
            return { facture: result.facture }
        } catch (err) {
            throw { message: err.message };
        }
    },
    getById: async (id = "") => {
        try {
            let result = await api.get("/api/v1/private/facture", { query: { id: id } });
            return { facture: result.facture }
        } catch (err) {
            throw { message: err.message };
        }
    },
    printPDF: async (facture = {}, globalState) => {
        try {
            let result = await pdf.facture(facture, true, globalState);
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    },
    savePDF: async (facture = {}, globalState) => {
        try {
            let result = await pdf.facture(facture, false, globalState);
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    },
}