import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import Modal from '../Modal';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';


function VehiculeMultipleChoiseSelector(props) {
    const intl = props.intl;
    const vehicules = props.vehicules;
    const [selected, setSelected] = useState(undefined);

    return <Modal display={props.display || false}
        onClose={() => {
            //props.onClose && props.onClose();
        }}>
        <Paper elevation={0}>
            <Grid container spacing={2}>
                <Grid size={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom component="div"><b>{intl.formatMessage({ id: 'vehicule.multiple.choise' })} : </b></Typography>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                {vehicules.map((v, idx) => {
                    return <Grid size={12} sx={{ textAlign: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid size={1} sx={{ textAlign: 'center' }}>
                                <Checkbox
                                    checked={idx == selected}
                                    onChange={(event => {
                                        setSelected(idx);
                                    })} />
                            </Grid>
                            <Grid size={11} sx={{ textAlign: 'left', alignContent: 'center' }}>
                                {v.designation || "unknow"}
                            </Grid>
                        </Grid>
                    </Grid>
                })}
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid size={6}>
                    <Button
                        disabled={selected == undefined}
                        variant="contained"
                        color="success"
                        sx={{ width: '100%' }}
                        onClick={()=>{
                            props.onValidate && props.onValidate(vehicules[selected]);
                        }}
                    >{intl.formatMessage({ id: 'button.validate' })}</Button>
                </Grid>
                <Grid size={6}>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}
                    >{intl.formatMessage({ id: 'button.cancel' })}</Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
}

export default injectIntl(VehiculeMultipleChoiseSelector);