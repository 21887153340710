import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigation } from '@remyar/react-navigation';
import { useParams } from "react-router";

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { withSnackBar } from '@remyar/react-snackbar';

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable';

import ForfaitAddProductModal from '../../components/ForfaitAddProductModal';
import ConfirmModal from '../../components/ConfirmModal';

import actions from '../../actions';
function ForfaitCreate(props) {

    const intl = props.intl;
    const params = useParams();

    const navigation = useNavigation();
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(undefined);
    const [displayProductAddToDevisModal, setDisplayProductAddToDevisModal] = useState(undefined);
    const [serviceOrProduct, setServiceOrProduct] = useState({ isService: false, values: [] });
    const [produits, setProduits] = useState([]);
    const [services, setServices] = useState([]);

    const [forfaitName, setForfaitName] = useState("");
    const [forfaitRef, setForfaitRef] = useState("")
    const [lines, setLines] = useState([]);

    async function fetchData() {
        let result = await actions.produits.getAll();
        setProduits(result.products.filter((el) => ((el.deleted !== 1) && (el.deleted !== true))));
        result = await actions.services.getAll();
        setServices(result.services.filter((el) => ((el.deleted !== 1) && (el.deleted !== true))));
    }

    async function _fetchData() {
        setDisplayLoader(true);
        try {
            await fetchData();
            if (params.id != undefined) {
                let result = await actions.forfait.getById(params.id);
                setForfaitName(result.forfait.nom);
                setForfaitRef(result.forfait.ref_fab);
                setLines([...result.forfait.products]);
            }
        } catch (err) {

        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        _fetchData();
    }, []);


    const headers = [
        { id: 'ref_fab', label: 'Code', minWidth: 100 },
        { id: 'nom', label: 'Désignation', minWidth: 100 },
        { id: 'qty', label: 'Quantité', minWidth: 100 },
    ];

    headers.push({
        id: 'actions', label: "", minWidth: 16, maxWidth: 16, align: "right", render: (row) => {
            return <span>
                <Tooltip title="Editer">
                    <EditIcon sx={{ cursor: 'pointer' }} onClick={() => {
                        setDisplayProductAddToDevisModal(row);
                    }}>
                    </EditIcon>
                </Tooltip>
                <Tooltip title="Supprimer">
                    <DeleteForeverIcon sx={{ color: 'red', cursor: 'pointer', marginLeft: '15px' }} onClick={() => {
                        setDisplayConfirmModal(row);
                    }} />
                </Tooltip>
            </span>
        }
    });

    let rows = lines.map((line, idx) => {
        return { ...line, qty: line.quantity, idx: idx };
    });

    return <Box sx={{ paddingBottom: '25px' }}>
        <Loader display={displayLoader} />

        <Grid container spacing={2}>
            <Grid item size={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom component="div"><b>{params.id == undefined ? intl.formatMessage({ id: 'forfait.add' }) : intl.formatMessage({ id: 'forfait.edit' })}</b></Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ paddingTop: '25px' }}>
            <Grid item size={6} sx={{ textAlign: 'center' }}>
                <TextField label="Référence" focused variant="outlined" sx={{ width: "100%", textAlign: "left" }} name="ref"
                    value={forfaitRef}
                    onChange={(event) => {
                        setForfaitRef(event.target.value);
                    }}
                />
            </Grid>
            <Grid item size={6} sx={{ textAlign: 'center' }}>
                <TextField label="Nom" focused variant="outlined" sx={{ width: "100%", textAlign: "left" }} name="name"
                    value={forfaitName}
                    onChange={(event) => {
                        setForfaitName(event.target.value);
                    }}
                />
            </Grid>
        </Grid>

        {rows && <DataTable sx={{ marginTop: '15px' }} headers={headers} rows={rows} />}

        {displayProductAddToDevisModal && <ForfaitAddProductModal
            display={displayProductAddToDevisModal != undefined}

            value={displayProductAddToDevisModal}
            title={serviceOrProduct.isService ? intl.formatMessage({ id: 'devis.add.service' }) : intl.formatMessage({ id: 'devis.add.product' })}
            isService={serviceOrProduct.isService}
            options={serviceOrProduct.values}
            onClose={() => { setDisplayProductAddToDevisModal(undefined); }}
            onValidate={(product, edit) => {
                if (edit == false) {
                    //-- ajout nouvelle ligne
                    lines.push(product);
                    setLines([...lines]);
                } else {
                    //-- modification de la ligne
                    lines[product.idx] = { ...product };
                    setLines([...lines]);
                }

                setDisplayProductAddToDevisModal(undefined);
            }}
        />}

        {displayConfirmModal && <ConfirmModal
            title={intl.formatMessage({ id: 'confirm.suppression' })}
            display={displayConfirmModal ? true : false}
            onClose={() => {
                setDisplayConfirmModal(undefined);
            }}
            onValidate={async () => {
                let lineToDelete = displayConfirmModal.idx;
                delete lines[lineToDelete];
                setLines([...(lines.filter((e) => e != undefined))]);
                setDisplayConfirmModal(undefined);
            }}
        />}

        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                key={'SaveDevis'}
                icon={<SaveIcon />}
                tooltipTitle={intl.formatMessage({ id: 'forfait.save' })}
                onClick={async () => {

                    let forfait = {
                        id: params.id,
                        ref_fab: forfaitRef,
                        nom: forfaitName,
                        products: [...lines],
                        isPending: false,
                    }

                    try {
                        await actions.forfait.create(forfait);
                        props.snackbar.success(intl.formatMessage({ id: 'forfait.save.success' }));
                    } catch (err) {
                        props.snackbar.error(err.message);
                    } finally {
                        navigation.goBack();
                    }
                }}
            />

            <SpeedDialAction
                key={'AddProduct'}
                icon={<AddIcon />}
                tooltipTitle={intl.formatMessage({ id: 'forfait.add.product' })}
                onClick={async () => {
                    setServiceOrProduct({ isService: false, values: produits });
                    setDisplayProductAddToDevisModal({});
                }}
            />

            <SpeedDialAction
                key={'AddService'}
                icon={<AddIcon />}
                tooltipTitle={intl.formatMessage({ id: 'forfait.add.service' })}
                onClick={async () => {
                    setServiceOrProduct({ isService: true, values: services });
                    setDisplayProductAddToDevisModal({});
                }}
            />

        </SpeedDial>
    </Box>
}

export default withSnackBar(injectIntl(ForfaitCreate));