import createAction from '../../middleware/actions';
import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/factures");

            result.factures = result.factures.map((el) => {
                let total = 0;
                el.products.forEach((product) => {
                    if (product.reduction && (parseFloat(product.reduction) > 0.0)) {
                        total += parseFloat((product.quantity|| 0).toString()) * (parseFloat((product.taux || 0).toString()) - (parseFloat((product.taux || 0).toString()) * (parseFloat((product.reduction || 0)) / 100.0)));
                    } else {
                        total += parseFloat((product.taux || 0).toString()) * parseFloat((product.quantity|| 0).toString());
                    }
                });

                return { ...el, total: total }
            })


            return { factures: result.factures }
        } catch (err) {
            throw { message: err.message };
        }
    }
}