import api from '../../api';
import pdf from "../../pdf";

export default {
    create: async (devi = {}) => {
        try {
            let result = await api.post("/api/v1/private/devi", devi);
            return { devi: result.devi }
        } catch (err) {
            throw { message: err.message };
        }
    },
    getLastNumber: async () => {
        try {
            let result = await api.get("/api/v1/private/devi", { query: { lastNumber: 0 }});
            return { devisNumber: result.lastNumber }
        } catch (err) {
            throw { message: err.message };
        }
    },
    getById: async (id = "") => {
        try {
            let result = await api.get("/api/v1/private/devi", { query: { id: id } });
            return { devi: result.devi }
        } catch (err) {
            throw { message: err.message };
        }
    },
    printPDF: async (devis = {}, globalState) => {
        try {
            let result = await pdf.devis(devis, true , globalState);
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    },
    savePDF: async (devis = {} , globalState) => {
        try {
            let result = await pdf.devis(devis, false, globalState);
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    },
}