import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useStore } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { useParams } from "react-router";

import actions from '../../actions';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PlaqueValue from '../../components/PlaqueValue';
import Paper from '@mui/material/Paper';

import Loader from '../../components/Loader';
import DatePicker from '../../components/DatePicker';
import DataTable from '../../components/DataTable';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';

import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDial from '@mui/material/SpeedDial';

import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import CreditScoreIcon from '@mui/icons-material/CreditScore';

function DevisDisplayPage(props) {

    let params = useParams();
    const [globalState, dispatch] = useStore();
    const intl = props.intl;
    const devis_id = params?.id ? params?.id : 0;

    const [devis, setDevis] = useState({});
    const [displayLoader, setDisplayLoader] = useState(true);

    async function fetchData() {
        try {
            let result = await actions.devi.getById(params.id);
            setDevis(result.devi);
        } catch (err) {
            props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);



    let devis_total = 0.0;
    let useReduction = false;
    let rows = devis?.products?.map((line) => {
        let obj = { ...line };
        if (line.isForfait == undefined || line.isForfait == false) {

            if (line.ref) {
                line.ref_fab = line.ref;
            }
            let tarif_total = parseFloat((line.quantity || 0).toString()) * parseFloat((line.taux || 0).toString());

            if (line.reduction && (parseFloat(line.reduction || 0) > 0.0)) {
                useReduction = true;
                tarif_total = parseFloat((line.quantity || 0).toString()) * (parseFloat((line.taux || 0).toString()) - (parseFloat((line.taux || 0).toString()) * (parseFloat(line.reduction || 0) / 100.0)));
            }

            devis_total += tarif_total;
            let tarif_vente = parseFloat((line.taux || 0).toString()).toFixed(2) + ' €';
            obj = { ...line, name: ((line.marque ? (line.marque + ' -') : '') + ' ' + (line.nom ? line.nom : line.commentaire ? line.commentaire : ' ')).trim(), info: '', qty: line.quantity, tarif_vente, tarif_total: tarif_total.toFixed(2) + ' €', reduction: line.reduction ? (parseFloat(line.reduction).toFixed(2) + " %") : "" };
        }
        return obj;
    });


    const headers = [
        { id: 'ref_fab', label: 'Code', minWidth: 100 },
        { id: 'nom', label: 'Désignation', minWidth: 100 },
        { id: 'qty', label: 'Quantité', minWidth: 100 },
        { id: 'tarif_vente', label: 'Tarif Unitaire', minWidth: 100 }
    ];

    useReduction && headers.push({ id: 'reduction', label: 'Remise', minWidth: 100 });
    headers.push({ id: 'tarif_total', label: 'Total TTC', minWidth: 100 });

    rows && rows.push({
        isCustom: true, render: () => {
            return <TableRow key="header" >
                <TableCell />
                <TableCell />
                <TableCell />
                {useReduction && <TableCell />}
                <TableCell>
                    <b>Total TTC :</b>
                </TableCell>
                <TableCell>
                    <b>{devis_total.toFixed(2) + ' €'}</b>
                </TableCell>
            </TableRow  >
        }
    });

    rows = rows?.map((row) => {
        if (row.isForfait == true) {
            return {
                isCustom: true,
                render: () => {
                    return <TableRow key="header" >
                        <TableCell colSpan={useReduction ? 6 : 5}>
                            <Typography ><b><i>{(row.ref_fab ? (row.ref_fab + " - ") : "") + row.nom}</i></b></Typography>
                        </TableCell>
                    </TableRow>
                }
            }
        } else {
            return row;
        }
    })

    let date = new Date(devis.vehicule?.immatriculationDate);

    return <Box sx={{ paddingBottom: '25px' }}>
        <Loader display={displayLoader} />

        {devis?.id && <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom component="div"><b>{intl.formatMessage({ id: 'devis.number' }) + " " + devis?.number}</b></Typography>
            </Grid>
        </Grid>}

        {devis?.id && <Grid container spacing={2} sx={{ paddingTop: '25px' }}>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label="Client" disabled variant="outlined" sx={{ width: "100%", textAlign: "center" }} value={devis?.client?.nom?.toUpperCase() + ' ' + devis?.client?.prenom} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12}>
                        <TextField disabled label="Adresse" variant="outlined" sx={{ width: "100%", textAlign: "left" }} multiline maxRows='3' minRows='3'
                            value={((devis?.client?.adresse1 || "") + (devis?.client?.adresse2?.length ? ('\n' + devis?.client?.adresse2 + '\n') : '\n') + devis?.client?.code_postal + ' ' + devis?.client?.ville)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12}>
                        < DatePicker disabled sx={{ width: '100%', minWidth: '100%' }} value={devis?.expiration} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label="Plaque" disabled variant="outlined" sx={{ width: "100%", textAlign: "center" }} value={devis?.vehicule?.plate} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12}>
                        <TextField label="Véhicule" disabled variant="outlined" sx={{ width: "100%", textAlign: "left" }} multiline maxRows='3' minRows='3'
                            value={devis.vehicule == undefined ? "" : (devis.vehicule?.designation || "") + "\r\n" +
                                intl.formatMessage({ id: 'devis.informations.first_immat' }) + " : " + date.getFullDate() + "-" + date.getFullMonth() + "-" + date.getFullYear() + "\r\n" +
                                intl.formatMessage({ id: 'devis.informations.vin' }) + " : " + (devis.vehicule?.vin || "")}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
                    <Grid item xs={12}>
                        <TextField label="Kilométrage" variant="outlined" sx={{ width: "100%", textAlign: "left" }} value={devis?.vehicule?.kilometrage}
                            onChange={(event) => {
                                let obj = { ...devis };
                                obj.vehicule.kilometrage = event.target.value;
                                setDevis(obj);
                            }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>}

        {rows && <DataTable sx={{ marginTop: '25px', marginBottom: '17px' }} headers={headers} rows={rows}>

        </DataTable>}

        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                key={'Print'}
                icon={<PrintIcon />}
                tooltipTitle={intl.formatMessage({ id: 'devis.print' })}
                onClick={async () => {
                    setDisplayLoader(true);
                    try {
                        await actions.devi.printPDF(devis, globalState);
                    } catch (err) {
                        props.snackbar.error(err.message);
                    } finally {
                        setDisplayLoader(false);
                    }
                }}
            />
            <SpeedDialAction
                key={'Save'}
                icon={<SaveIcon />}
                tooltipTitle={intl.formatMessage({ id: 'devis.download' })}
                onClick={async () => {
                    setDisplayLoader(true);
                    try {
                        await actions.devi.savePDF(devis, globalState);
                    } catch (err) {
                        props.snackbar.error(err.message);
                    } finally {
                        setDisplayLoader(false);
                    }
                }}
            />
            <SpeedDialAction
                key={'ConvertToBilling'}
                icon={<CreditScoreIcon />}
                tooltipTitle={intl.formatMessage({ id: 'devis.convert.to.billing' })}
                onClick={async () => {
                    delete devis.facture_id;
                    let devi = { ...devis };
                    devi.devis_id = devi.id;
                    delete devi.id;
                    devi.paye = false;
                    let now = new Date()
                    devi.date = now.getTime();
                    devi.expiration = now.addMonths(1).getTime();
                    let facture = (await actions.facture.create(devi))?.facture || {};
                    devis.facture_id = facture.id;
                    await actions.devi.create(devis);
                    props.snackbar.success(intl.formatMessage({ id: 'devis.convert.to.billing.success' }));
                }}
            />
        </SpeedDial>

    </Box >;
}

export default withSnackBar(injectIntl(DevisDisplayPage));