import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/forfaits");
            return { forfaits: result.forfaits }
        } catch (err) {
            throw { message: err.message };
        }
    }
}