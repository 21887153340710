import urlIndex from './url.index';
import urlHome from './url.home';
import urlInstall from './url.install';
import urlSettings from './url.settings';
import urlClients from './url.clients';
import urlVehicules from './url.vehicules';
import urlProduits from './url.produits';
import urlDevis from './url.devis';
import urlDevisCreate from './url.devis.create';
import urlDevisEdit from './url.devis.edit';
import urlDevisDisplay from './url.devis.display';
import urlBillings from './url.billings';
import urlBillingDisplay from './url.billing.display';
import urlServices from './url.services';
import urlCatalog from './url.catalog';
import urlTechnics from './url.technics';
import urlTechnicsDetails from './url.technics.details';
import urlTechnicsAdjustments from './url.technics.adjustments';
import urlLogin from './url.login';
import urlSignup from './url.signup';
import urlForgotPassword from './url.forgot.password';
import urlForfaits from './url.forfaits';
import urlPlanning from './url.planning';
import urlForfaitCreate from './url.forfait.create';
import urlForfaitDisplay from './url.forfait.display';
import urlForfaitEdit from './url.forfait.edit';

export default {
    urlIndex,
    urlHome,
    urlInstall,
    urlSettings,
    urlClients,
    urlVehicules,
    urlProduits,
    urlDevis,
    urlDevisCreate,
    urlDevisEdit,
    urlDevisDisplay,
    urlBillings,
    urlBillingDisplay,
    urlServices,
    urlCatalog,
    urlForfaits,
    urlTechnics,
    urlTechnicsDetails,
    urlTechnicsAdjustments,
    urlLogin,
    urlSignup,
    urlForgotPassword,
    urlForfaitCreate,
    urlForfaitDisplay,
    urlForfaitEdit,
    urlPlanning
}