import api from '../../api';

export default {
    create: async (forfait = {}) => {
        try {
            let result = await api.post("/api/v1/private/forfait", forfait);
            return { forfait: result.forfait }
        } catch (err) {
            throw { message: err.message };
        }
    },
    getById: async (id = "") => {
        try {
            let result = await api.get("/api/v1/private/forfait", { query: { id: id } });
            return { forfait: result.forfait }
        } catch (err) {
            throw { message: err.message };
        }
    },
    delete: async (id) => {
        try {
            let result = await api.del("/api/v1/private/forfait", { id });
        } catch (err) {
            throw { message: err.message };
        }
    },
}