import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/vehicules");
            return { vehicules: result.vehicules }
        } catch (err) {
            throw { message: err.message };
        }
    }
}