import api from '../../api';

export default {
    create: async (client = {}) => {
        try {
            let result = await api.post("/api/v1/private/client", client);
            return { client: result.client }
        } catch (err) {
            throw { message: err.message };
        }
    },
    update: async (client = {}) => {
        try {
            let result = await api.post("/api/v1/private/client", client);
            return { client: result.client }
        } catch (err) {
            throw { message: err.message };
        }
    },
    delete: async (id) => {
        try {
            let result = await api.del("/api/v1/private/client", { id });
        } catch (err) {
            throw { message: err.message };
        }
    },
}