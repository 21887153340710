import { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useStore } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { useNavigation } from '@remyar/react-navigation';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import TodayIcon from '@mui/icons-material/Today';
import MemoryIcon from '@mui/icons-material/Memory';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddCardIcon from '@mui/icons-material/AddCard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LogoutIcon from '@mui/icons-material/Logout';
import ConstructionIcon from '@mui/icons-material/Construction';

import routeMdw from '../../routes';
import actions from '../../actions';

function MyDrawer(props) {
    const intl = props.intl;

    const [globalState, dispatch] = useStore();
    const navigation = useNavigation();

    return <Drawer open={props.open} onClose={() => { props.onClose && props.onClose() }}>
        <List>

            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlHome());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.index' })} secondary={intl.formatMessage({ id: 'url.index.desc' })} />
            </ListItem>
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlPlanning());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <TodayIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.planning' })} secondary={intl.formatMessage({ id: 'url.planning.desc' })} />
            </ListItem>
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlClients());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.clients' })} secondary={intl.formatMessage({ id: 'url.clients.desc' })} />
            </ListItem>
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlVehicules());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.vehicules' })} secondary={intl.formatMessage({ id: 'url.vehicules.desc' })} />
            </ListItem>
            {globalState.settings?.useCatalog && <ListItem button onClick={() => {
                navigation.push(routeMdw.urlCatalog());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.catalog' })} secondary={intl.formatMessage({ id: 'url.catalog.desc' })} />
            </ListItem>}

            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlProduits());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.produits' })} secondary={intl.formatMessage({ id: 'url.produits.desc' })} />
            </ListItem>
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlServices());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.services' })} secondary={intl.formatMessage({ id: 'url.services.desc' })} />
            </ListItem>
            {((process.env.REACT_APP_USE_FORFAIT != undefined) && (process.env.REACT_APP_USE_FORFAIT == true) || (process.env.REACT_APP_USE_FORFAIT == "true")) && <ListItem button onClick={() => {
                navigation.push(routeMdw.urlForfaits());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.forfaits' })} secondary={intl.formatMessage({ id: 'url.forfaits.desc' })} />
            </ListItem>}
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlDevis());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <AddCardIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.devis' })} secondary={intl.formatMessage({ id: 'url.devis.desc' })} />
            </ListItem>
            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlBillings());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <AddCardIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.billings' })} secondary={intl.formatMessage({ id: 'url.billings.desc' })} />
            </ListItem>
            {/*<ListItem button onClick={() => {
                props.navigation.push(routeMdw.urlLogin());
                props.onClose && props.onClose();
            }}>
                <ListItemIcon>
                    <AddCardIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.login' })} secondary={intl.formatMessage({ id: 'url.login.desc' })} />
        </ListItem>*/}
        </List>
        <List sx={{ position: "absolute", bottom: "0px", width: "100%" }}>

            {/*<ListItem button onClick={() => {
                props.navigation.push(routeMdw.urlTechnics());
                props.onClose && props.onClose();
            }}>
                <ListItemIcon>
                    <ConstructionIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.technics' })} secondary={intl.formatMessage({ id: 'url.technics.desc' })} />
        </ListItem>*/}



            <ListItem button onClick={() => {
                navigation.push(routeMdw.urlSettings());
                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.settings' })} secondary={intl.formatMessage({ id: 'url.settings.desc' })} />
            </ListItem>

            <ListItem button onClick={async () => {
                try {
                    await dispatch(actions.vehicule.setSelected(undefined));
                    await dispatch(actions.user.logout());
                    navigation.push(routeMdw.urlLogin());
                } catch (err) {

                }

                props.onClose && props.onClose();
            }}
                sx={{ cursor: "pointer" }}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'url.logout' })} secondary={intl.formatMessage({ id: 'url.logout.desc' })} />
            </ListItem>

        </List>
    </Drawer>;
}


export default injectIntl(MyDrawer);