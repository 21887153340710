import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams } from "react-router";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable';

import actions from '../../actions';

function ForfaitsDisplay(props) {

    let params = useParams();

    const [displayLoader, setDisplayLoader] = useState(true);
    const [forfait, setForfait] = useState({});
    const [lines, setLines] = useState([]);

    const intl = props.intl;


    useEffect(() => {
        async function fetchData() {
            try {
                let result = await actions.forfait.getById(params.id);
                setForfait(result.forfait);
                setLines([...result.forfait.products]);
            } catch (err) {
                props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
            } finally {
                setDisplayLoader(false);
            }
        }
        fetchData();
    }, []);

    const headers = [
        { id: 'ref_fab', label: 'Code', minWidth: 100 },
        { id: 'nom', label: 'Désignation', minWidth: 100 },
        { id: 'qty', label: 'Quantité', minWidth: 100 },
    ];

    let rows = lines.map((line, idx) => {
        return { ...line, qty: line.quantity };
    });

    return <Box sx={{ paddingBottom: '25px' }}>
        <Loader display={displayLoader} />

        {forfait?.id && <Grid container spacing={2}>
            <Grid item size={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom component="div"><b>{intl.formatMessage({ id: 'forfait.title' }) + " : " + forfait?.ref_fab + " - " + forfait?.nom}</b></Typography>
            </Grid>
        </Grid>}

        {rows && <DataTable sx={{ marginTop: '25px', marginBottom: '17px' }} headers={headers} rows={rows}/>}

    </Box>
}


export default injectIntl(ForfaitsDisplay);