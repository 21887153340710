import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/clients");
            return { clients: result.clients }
        } catch (err) {
            throw { message: err.message };
        }
    }
}