import createAction from '../../middleware/actions';
import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/public/changelog");
            return { changelog: result.changelog };
        } catch (err) {
            throw { message: err.message };
        }
    },
}