import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/user/settings");
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    },
    save: async (settings) => {
        try {
            let result = await api.post("/api/v1/private/user/settings", settings);
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    },
    saveLogo: async (logo) => {
        try {
            let result = await api.post("/api/v1/private/user/settings", { logo });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    },
    saveEntreprise: async (entreprise) => {
        try {
            let result = await api.post("/api/v1/private/user/settings", { entreprise });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    },
    savePaiement: async (paiement) => {
        try {
            let result = await api.post("/api/v1/private/user/settings", { paiement });
            return { settings: result.settings }
        } catch (err) {
            throw { message: err.message };
        }
    }
}