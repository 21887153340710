import { useState } from 'react';
import { Routes, Route } from 'react-router';
import { useStore } from '@remyar/react-store';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';

import _routes from './routes';

import InstallPage from './pages/install';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import SettingsPage from './pages/settings';
import CataloguePage from './pages/catalogue';
import ForgotPasswordPage from './pages/forgotPassword';
import ClientsPage from './pages/clients';
import ProduitsPage from './pages/produits';
import ServicesPage from './pages/services';
import VehiculesPage from './pages/vehicules';
import DevisPage from './pages/devis';
import DevisCreatePage from './pages/devisCreate';
import DevisDisplayPage from './pages/devisDisplay';
import FacturesPage from './pages/factures';
import FacturesDisplayPage from './pages/facturesDisplay';
import SignupPage from './pages/signup';
import SchedulPage from './pages/schedul';
import ForfaitsPage from './pages/forfaits';
import ForfaitCreatePage from './pages/forfaitsCreate';
import ForfaitDisplayPage from "./pages/forfaitsDisplay";

const routes = [
    { path: _routes.urlIndex(), name: 'LoginPage', Component: <InstallPage /> },
    { path: _routes.urlLogin(), name: 'LoginPage', Component: <LoginPage /> },
    { path: _routes.urlHome(), name: 'HomePage', Component: <HomePage /> },
    { path: _routes.urlSettings(), name: 'SettingsPage', Component: <SettingsPage /> },
    { path: _routes.urlForgotPassword(), name: 'ForgotPasswordPage', Component: <ForgotPasswordPage /> },
    { path: _routes.urlClients(), name: 'ClientsPage', Component: <ClientsPage /> },
    { path: _routes.urlProduits(), name: 'ProduitsPage', Component: <ProduitsPage /> },
    { path: _routes.urlServices(), name: 'ServicesPage', Component: <ServicesPage /> },
    { path: _routes.urlVehicules(), name: 'VehiculesPage', Component: <VehiculesPage /> },
    { path: _routes.urlDevis(), name: 'DevisPage', Component: <DevisPage /> },
    { path: _routes.urlDevisCreate(), name: 'DevisCreatePage', Component: <DevisCreatePage /> },
    { path: _routes.urlDevisEdit(':id'), name: 'DevisEditPage', Component: <DevisCreatePage /> },
    { path: _routes.urlDevisDisplay(':id'), name: 'DevisDisplayPage', Component: <DevisDisplayPage /> },
    { path: _routes.urlBillings(), name: 'FacturesPage', Component: <FacturesPage /> },
    { path: _routes.urlBillingDisplay(':id'), name: 'FacturesDisplayPage', Component: <FacturesDisplayPage /> },
    { path: _routes.urlSignup(), name: 'SignupPage', Component: < SignupPage /> },
    { path: _routes.urlPlanning(), name: 'SchedulPage', Component: <SchedulPage /> },
    { path: _routes.urlForfaits(), name: 'ForfaitsPage', Component: <ForfaitsPage /> },
    { path: _routes.urlForfaitCreate(), name: 'ForfaitsCreatePage', Component: <ForfaitCreatePage /> },
    { path: _routes.urlForfaitDisplay(':id'), name: 'ForfaitDisplayPage', Component: <ForfaitDisplayPage /> },
    { path: _routes.urlForfaitEdit(':id'), name: 'ForfaitEditPage', Component: <ForfaitCreatePage /> },
    { path: _routes.urlCatalog(), name: 'CataloguePage', Component: <CataloguePage  />}
];

function App(props) {

    const [drawerState, setDrawerState] = useState(false);
    const [globalState, dispatch] = useStore();

    const selectedVehicule = globalState.selectedVehicule;

    return <Box >
        <AppBar onClick={() => { globalState.user && setDrawerState(true) }} title={(selectedVehicule?.plate && selectedVehicule?.designation) ? selectedVehicule?.plate + " : " + selectedVehicule?.designation : undefined} />
        <Box sx={{ paddingTop: '64px' }} >
            <Container maxWidth="xl" sx={{ paddingTop: "25px" }} >
                <Drawer
                    open={drawerState}
                    onClose={() => { setDrawerState(false) }}
                />
                <Routes >
                    {routes.map(({ path, Component }) => (
                        <Route path={path} key={path} element={Component} />
                    ))}
                </Routes>
            </Container>
        </Box>
    </Box>
}

export default App;