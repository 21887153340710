
import api from '../../api';

export default {
    getAll: async () => {
        try {
            let result = await api.get("/api/v1/private/services");
            return { services: result.services }
        } catch (err) {
            throw { message: err.message };
        }
    },
}
