import { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigation } from '@remyar/react-navigation';
import { useStore } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';


import actions from '../../actions';
import routeMdw from '../../routes';
import Loader from '../../components/Loader';
import utils from '../../utils';

function InstallPage(props) {

    const intl = props.intl;

    const [globalState, dispatch] = useStore();
    const navigation = useNavigation();

    async function fetchData() {

        try {
            let response = await dispatch(actions.user.getSession());
            if (response.message) {
                props.snackbar.info(intl.formatMessage({ id: response.message }));
            }
            await dispatch(actions.codesPostaux.getAll());
            await dispatch(actions.settings.getAll());
            navigation.push(routeMdw.urlHome());
        } catch (err) {
            if ( err.message == utils.getCause()){
                props.snackbar.error(intl.formatMessage({ id: 'lost.session' }));
            }
            await dispatch(actions.codesPostaux.getAll());
            navigation.push(routeMdw.urlLogin());
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return <Loader display={true} />;
}

export default withSnackBar(injectIntl(InstallPage));