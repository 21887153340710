import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputNumber from '../InputNumber';
import { withSnackBar } from '@remyar/react-snackbar';
import Switch from '@mui/material/Switch';

function ForfaitAddProductModal(props) {

    const intl = props.intl;

    const [product, setProduct] = useState(props.value?.ref_fab ? props.value : {});
    const [quantity, setQuantity] = useState(props.value?.qty || 1);
  //  const [useReduction, setUseReduction] = useState(((props.value?.reduction == undefined) || (props.value?.reduction?.length == 0)) ? false : true);
   // const [reduction, setReduction] = useState(props.value?.reduction?.replace('%', '')?.trim() || 0);

    const title = props.title || "";
    const products = props.options || [];

    return <Modal display={props.display || false} >
        <Paper elevation={0}>
            {title.length > 0 && <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom component="div"><b>{title}</b></Typography>
                </Grid>
            </Grid>}
            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Autocomplete
                        disablePortal
                        getOptionLabel={(option) => option?.ref_fab + ' - ' + option?.nom}
                        options={products}
                        defaultValue={product?.ref_fab ? props.value : undefined}
                        onChange={(event, value) => {
                            setProduct(value);
                        }}
                        renderInput={(params, option) => <TextField name="marque" {...params} label={intl.formatMessage({ id: 'devis.select.product' })} variant="outlined" sx={{ width: "100%", textAlign: "center" }} />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <InputNumber value={quantity} label="Quantité" variant="outlined" sx={{ width: "100%", textAlign: "center" }} name="qty" onChange={(value) => {
                        setQuantity(value || 0)
                    }} component={TextField} />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            if (product && product.id != undefined) {
                                if (props.value?.idx != undefined) {
                                    product.idx = props.value?.idx;
                                }
                                product.quantity = quantity;
                               // product.reduction = reduction;
                                product.isService = props.isService || false;
                                props.onValidate && props.onValidate({ ...product }, props.value?.ref_fab ? true : false);
                            } else {
                                props.snackbar.error(intl.formatMessage({ id: 'devis.no.product.selected' }));
                            }
                        }}
                    >{intl.formatMessage({ id: 'button.validate' })}</Button>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}
                    >{intl.formatMessage({ id: 'button.cancel' })}</Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>;
}


export default injectIntl(withSnackBar(ForfaitAddProductModal));